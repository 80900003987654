
import store from '@/store'
import { getMaterial, getMaterialCategory } from '@/services/materialServices'
export default {
  methods: {

    // 获取素材列表
    async fetchMaterialList() {
      const state = store.state.material
      try {
        store.commit('material/setIsLoading', true)
        const { type, pageIndex } = state.queryParams
        const params = JSON.parse(JSON.stringify({ ...state.queryParams, catId: type == 4 ? 22000 : type }))
        if (params.groupIds && params.groupIds.length) delete params.groupCodes
        const res = await getMaterial(params)
        store.commit('material/setIsLoading', false)
        if (res.code === 0) {
          const list = pageIndex == 1 ? [...res.data.dataList] : [...state.materialList, ...res.data.dataList || []]
          store.commit('material/setMaterialList', list)
          store.commit('material/setMaterialCount', res.data.page.totalCount)
          if (list.length === res.data.page.totalCount) {
            store.commit('material/setIsFinished', true)
          }
          else {
            store.commit('material/setIsFinished', false)
          }
        }
      }
      catch (e) {
        store.commit('material/setIsLoading', false)
      }
    },

    // 获取素材分组列表
    async fetchMaterialCategoryList(params = {}) {
      const res = await getMaterialCategory({ ...params, groupCodes: ['OPA_DIS_ROOT'] })
      if (res.code == 1) {
        if (res.data.length) {
          store.commit('material/setCategoryList', res.data)
        }
      }
    },

    async fetchLinkMaterialList() {
      this.fetchMaterialList()
      // try {
      //   const state = store.state.material
      //   store.commit('material/setIsLoading', true)
      //   const res = await getLinkMaterial(state.queryParams)
      //   store.commit('material/setIsLoading', false)
      //   if (res.code === 0) {
      //     const list = [...state.materialList, ...res.data.dataList]
      //     store.commit('material/setMaterialList', list)
      //     store.commit('material/setMaterialCount', res.data.page.totalCount)
      //     if (list.length === res.data.page.totalCount) {
      //       store.commit('material/setIsFinished', true)
      //     }
      //     else {
      //       store.commit('material/setIsFinished', false)
      //     }
      //   }
      // }
      // catch (e) {
      //   store.commit('material/setIsLoading', false)
      // }
    }
  }
}

