<template>
  <div class="material-index">
    <div>
      <search-bar />
      <div class="content-box">
        <base-material />
      </div>
      <buttom-bar />
    </div>
  </div>
</template>

<script>
import SearchBar from './components/search-bar.vue'
import ButtomBar from './components/buttom-bar.vue'
import BaseMaterial from './components/base-material.vue'

export default {
  name: 'Material',
  components: {
    SearchBar,
    ButtomBar,
    BaseMaterial
  },
  data() {
    return {
      inited: false
    }
  }
}
</script>

<style lang="less" scoped>
.material-index {
  height: 100vh;
  overflow: hidden;
  .content-box {
    height: calc(100vh - 112px);
    overflow: auto;
    margin-top: 62px;
  }
}
</style>
