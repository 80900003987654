<template>
  <div class="item">
    <div class="item-header van-ellipsis">
      <div class="icon-circle"></div>
      <span class="title">{{ material.title }}</span>
    </div>
    <div class="item-body">
      <div class="title">{{ material.title }}</div>
      <div class="container">
        <div class="text van-multi-ellipsis--l3">
          {{ material.description }}
        </div>
        <div class="img">
          <van-image
            width="72"
            height="72"
            fit="cover"
            :src="material.simpleImgUrl"
          />
        </div>
      </div>
    </div>
    <div class="item-footer">
      <div class="share-btn" @click.stop="$emit('on-share', material)">{{$t('发送')}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    material: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped>
.item {
  padding: 10px 16px 0 16px;
  box-sizing: border-box;
  margin-bottom: 16px;
  .item-header {
    .icon-circle {
      display: inline-block;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      border: rgba(0, 0, 0, 0.6) solid 4px;
      vertical-align: middle;
      box-sizing: border-box;
    }
    .title {
      font-size: 14px;
      margin-left: 6px;
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .item-body {
    border: rgba(0, 0, 0, 0.6) solid 1px;
    margin-top: 8px;
    padding: 8px;

    .title {
      font-size: 16px;
      box-sizing: border-box;
    }
    .container {
      position: relative;
      margin-top: 8px;

      font-size: 14px;
      line-height: 22px;
      height: 72px;
      .text {
        padding-right: 75px;
        box-sizing: border-box;
      }

      .img {
        flex: 0 0 72px;
        margin-left: 8px;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }

  .item-footer {
    text-align: right;
    .share-btn {
      display: inline-block;
      width: 60px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      cursor: pointer;
      font-size: 14px;
      background-color: rgba(0, 0, 0, 0.6);
      color: #fff;
      margin-top: 8px;
    }
  }
}
</style>
