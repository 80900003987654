<template>
  <div class="item">
    <div class="item-box">
      <div
        v-if="material.detailUrl"
        class="card-l"
        style="font-size: 0;"
        @click.stop="toPopup(material)"
      >
        <van-image
          :src="material.detailUrl"
          width="100%"
          height="118"
          radius="4"
          fit="cover"
        />
      </div>
      <div class="bom">
        <div class="two">
          <div class="title">{{ titleCom }}</div>
        </div>
        <a class="box1 activeColor" @click.stop="$emit('on-share', material)">
          <!-- <i style="font-size: 24px" class="iconfont iconfasong"></i> -->
          <van-icon name="share-o" size="16" />
        </a>
      </div>
      <van-image-preview v-model="showPopup" :images="[pupImg]" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    material: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      images: [],
      showPopup: false,
      pupImg: ''
    }
  },
  computed: {
    titleCom() {
      return this.material.title
    }
  },
  methods: {
    // 图片太大，直接显示。使用企微长按发送
    toPopup(par) {
      this.pupImg = par.detailUrl
      this.showPopup = true
    }
    
  }
}
</script>
<style lang="less" scoped>
.item {
  margin-top: 10px;
  margin-left: 10px;
  .item-box {
    position: relative;
    width: 100%;
    // height: 171px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
    /deep/ .van-overlay {
      z-index: 2001 !important;
    }
    /deep/ .van-checkbox {
      left: 10px;
      position: absolute;
      top: 10px;
    }
    /deep/ .van-checkbox__label {
      width: 92%;
    }
    .card-img {
      height: 118px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }
  .line-height {
    line-height: 53px;
    padding: 0 8px !important;
  }
  .bom {
    display: flex;
    width: 100%;
    padding: 4px 8px 4px 12px;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    .two {
      margin: 4px 0;
      .title {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
    .box1 {
      padding: 4px;
      color: #999999;
      display: flex;
      align-items: center;
      justify-content: center;
      .title {
        font-size: 14px;
      }
      &.activeColor {
        color: #287de1;
      }
    }
  }
}
</style>
<style lang="less">
.item {
  /deep/ .van-image-preview {
    z-index: 3003 !important;
  }
  /deep/ .van-swipe {
    z-index: 3002 !important;
    background-color: rgba(0, 0, 0, 0.9);
  }
  /deep/ .van-image-preview__overlay {
    background-color: rgba(255, 255, 255, 0);
  }
}
</style>
