<template>
  <div class="base-material">
    <van-list
      :immediate-check="false"
      :finished="listFinished"
      finished-text=""
      offset="50"
      class="material-list"
      @load="onLoadMore"
    >
      <div
        v-for="material in materialList"
        :key="material.id"
        class="material-item"
        :class="{full: ['FileMaterial', 'LinkMaterial'].includes(cmpMaterialType)}"
      >
        <component
          :is="cmpMaterialType"
          :material="material"
          @on-share="onShareMaterial"
        />
      </div>
    </van-list>
    <div v-if="materialList.length === 0" class="frame-144">
      <div class="frame-144-img">
        <img src="./img/frame_144.png" alt="" />
      </div>
      <div class="frame-title">
        {{$t('暂无素材')}}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import wxSDK from '@/scripts/wxsdk'
import { getMaterialId, articleMaterialDetail } from '@/services/materialServices'
import { getWxEntry } from '@/utils'
import ImageMaterial from './image-material.vue'
import VideoMaterial from './video-material.vue'
import FileMaterial from './file-material.vue'
import LinkMaterial from './link-material.vue'
import mixins from './mixins'
import loading from '@/utils/loading'
export default {
  components: {
    ImageMaterial,
    VideoMaterial,
    FileMaterial,
    LinkMaterial
  },
  mixins: [mixins],
  props: {
    materialType: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      finishedText: '',
      listLoading: false,
      pageEntry: ''
    }
  },
  computed: {
    ...mapState({
      materialList: state => state.material.materialList,
      materialCount: state => state.material.materialCount,
      queryParams: state => state.material.queryParams,
      isLoading: state => state.material.isLoading,
      listFinished: state => state.material.isFinished
    }),

    // 根本素材类型显示不同类型组件
    cmpMaterialType() {
      switch (this.queryParams.type - 1) {
        case 1:
          return 'VideoMaterial'
        case 2:
          return 'FileMaterial'
        case 3:
          return 'LinkMaterial'
        default:
          return 'ImageMaterial'
      }
    }
  },
  watch: {
    isLoading(val) {
      this.listLoading = val
    }
  },
  async mounted() {
    // try {
    //   this.pageEntry = await getWxEntry()
    //   console.log(this.pageEntry, 'this.pageEntry')
    // } catch (error) {}
  },
  async created() {
    // this.$store.dispatch('material/fetchMaterialList')
    this.$store.commit('material/setQueryParams', {
      pageIndex: 1
    })
    this.fetchMaterialList()
  },

  methods: {
    /**
     * 发送素材
     */
    async onShareMaterial(material) {
      if (!this.pageEntry) {
        try {
          this.pageEntry = await getWxEntry()
          console.log(this.pageEntry, 'this.pageEntry')
        } catch (error) {}
      }
      if (this.pageEntry !== 'single_chat_tools') {
        this.sendCosNews(material)
        return
      }
      const typeList = ['image', 'video', 'file', 'news']
      const type = typeList[this.queryParams.type - 1]
      if (type !== 'news') {
        const data = {
          url: material.detailUrl,
          type,
          fileName: material.detailUrl.split('/').pop()
        }
        loading.showLoading()
        const res = await getMaterialId(data).catch(()=>loading.hideLoading())
        loading.hideLoading()
        if (res.code === 0) {
          const params = {
            mediaid: res.data
          }
          wxSDK.sendChatMessage(type, params)
        } else {
          this.$toast(res.msg)
        }
      }
      else {
        const detailUrl = `${location.protocol}//${location.host}/vSearch/articleDetail?id=${material.contentId}&catId=${material.catId}&notInQw=1`
        const params = {
          // link: '' + material.detailUrl,
          link: '' + detailUrl,
          title: '' + material.title,
          desc: '' + material.description,
          imgUrl: '' + material.simpleImgUrl
        }
        wxSDK.sendChatMessage(type, params)
      }
    },
    async sendCosNews(material) {
      const typeList = ['graphicDetail', 'videoDetail', 'fileDetail', 'articleDetail']
      const pathName = typeList[this.queryParams.type - 1]
      const detailUrl = `${location.protocol}//${location.host}/vSearch/${pathName}?id=${material.contentId}&catId=${material.catId}&notInQw=1`
      // this.$router.push(`/vSearch/${pathName}?id=${material.contentId}&catId=${material.catId}&notInQw=1`)
      const params = {
        link: '' + detailUrl,
        title: '' + material.title,
        desc: '' + material.description,
        imgUrl: material.simpleImgUrl || material.detailUrl
      }
      wxSDK.shareAppMessage(params)
    
    },
    /**
     * 加载素材列表
     */
    onLoadMore() {
      if (this.isLoading) return
      if (this.materialList.length < this.materialCount) {
        this.$store.commit('material/setQueryParams', {
          pageIndex: this.queryParams.pageIndex + 1
        })
        if (this.queryParams.type === 4) {
          // this.$store.dispatch('material/fetchLinkMaterialList')
          this.fetchLinkMaterialList()
        }
        else {
          // this.$store.dispatch('material/fetchMaterialList')
          this.fetchMaterialList()
        }
      }
      else {
        this.finishedText = this.$t('没有更多了')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.material-list {
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  .material-item {
    width: 50%;
    &.full {
      width: 100%;
    }
  }
}
.frame-144 {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -62px;
  transform: translate(-50%, -50%);
  z-index: 99;
  width: 100%;
  .frame-144-img {
    width: 189px;
    height: 184px;
    margin: 0 auto;
  }
  .frame-title {
    color: #999999;
    font-size: 16px;
  }
}
</style>
