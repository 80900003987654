<template>
  <div class="search-box">
    <van-search
      v-model="searchValue"
      show-action
      :placeholder="$t('请输入搜索关键词')"
      :maxlength="30"
      @search="onSearch"
      @clear="onSearch"
    >
      <template #action>
        <div class="right-action" @click="onClickFilter">
          <van-icon :name="require('@/images/filter.svg')" size="24" />
        </div>
      </template>
    </van-search>

    <van-popup
      v-model="isShowFilter" 
      position="top" 
      :style="{
        top: '62px',
        left: '50%',
        transform: 'translateX(-50%)',
        'max-width': '500px'
      }"
    >
      <div class="popup-tags">
        <van-tabs
          v-if="isShowFileType"
          v-model="activeTab"
          class="talk-skill-tab"
          background="#ffffff"
          color="#2F7DCD"
          title-active-color="#467DB9"
          title-inactive-color="#000000"
          :ellipsis="false"
          @click="onTabActive"
        >
          <van-tab
            v-for="(item, index) in materialTypeList"
            :key="item"
            :name="index"
            :title="item"
          >
          </van-tab>
        </van-tabs>

        <div
          v-for="category in categoryList"
          :key="category.groupId"
          class="tags-box"
        >
          <div class="title">{{ category.groupName }}</div>
          <div class="first-tags-box">
            <span
              class="tag-title"
              :class="checkGroupId === category.groupId ? 'tag-active' : ''"
              @click="onChangeGroup(category.groupId, category.childPath)"
            >
              {{$t('全部')}}
            </span>
            <span
              v-for="child in category.childPath"
              :key="child.groupId"
              class="tag-title"
              :class="checkGroupId === child.groupId ? 'tag-active' : ''"
              @click="onChangeGroup(child.groupId)"
            >
              {{ child.groupName }}
            </span>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import mixins from './mixins'

export default {
  mixins: [mixins],
  data() {
    return {
      searchValue: '',
      activeTab: 0,
      isShowAction: true,
      isShowFilter: false,
      isShowFileType: true,
      materialTypeList: [this.$t('图片'), this.$t('视频'), this.$t('文件'), this.$t('图文')],
      checkGroupId: '',
    }
  },
  computed: {
    categoryList() {
      try {
        const list = this.$store.state.material.categoryList
        if (list.length == 1) {
          const childPath = list[0].childPath || []
          const id = this.activeTab == 3 ? 22000 : this.activeTab + 1
          return childPath.find(({ catId }) => catId == id).childPath
        }
      } catch (error) {}
      return []
    },
  },

  watch: {
    activeTab(val, oVal) {
      // 当在图片、视频、文件之类切换时不需要再调分组接口
      // 只有在图文和其他类型切换时，才需要重新请求接口
      if (val !== oVal) {
        if (val === 3) {
          // this.$store.dispatch('material/fetchMaterialCategoryList', {
          //   catId: 22000
          // })
          this.fetchMaterialCategoryList({
            catId: 22000,
          })
        } else {
          this.$store.dispatch('material/fetchMaterialCategoryList', {
            catId: val + 1,
          })
          this.fetchMaterialCategoryList({ catId: val + 1 })
        }
      }
    },
  },

  created() {
    // this.$store.dispatch('material/fetchMaterialCategoryList', { catId: 1 })
    this.fetchMaterialCategoryList({ catId: 1 })
  },

  methods: {
    onClickFilter() {
      this.isShowFilter = !this.isShowFilter
    },

    onSearch() {
      this.$store.commit('material/setQueryParams', {
        title: this.searchValue,
        pageIndex: 1,
      })
      this.$store.commit('material/setMaterialList', [])
      this.executeSearch()
    },

    onTabActive(index) {
      this.$store.commit('material/setQueryParams', {
        type: index + 1,
        pageIndex: 1,
      })
      this.checkGroupId = ''
      this.$store.commit('material/setQueryParams', { ...this.$store.state.material.queryParams, groupIds: [] })
      this.$store.commit('material/setMaterialList', [])
      this.executeSearch()
    },
    onChangeGroup(groupId, childPath = []) {
      let groupList = []
      let params = {}
      if (groupId !== this.checkGroupId) {
        this.checkGroupId = groupId
        const childList = childPath.map((child) => child.groupId)
        groupList = [groupId, ...childList]
      } else {
        this.checkGroupId = ''
      }
      params = { groupIds: groupList, pageIndex: 1 }
      this.$store.commit('material/setQueryParams', params)
      this.$store.commit('material/setMaterialList', [])
      this.executeSearch()
    },

    executeSearch() {
      if (this.activeTab === 3) {
        // this.$store.dispatch('material/fetchLinkMaterialList')
        this.fetchLinkMaterialList()
      } else {
        // this.$store.dispatch('material/fetchMaterialList')
        this.fetchMaterialList()
      }
    },
  },
}
</script>

<style lang="less" scoped>
.search-box {
  position: fixed;
  margin: 0 auto;
  width: 100%;
  max-width: 500px;
  top: 0;
  z-index: 3000;
  .van-search {
    padding: 12px 16px;
    z-index: 3000;
    position: relative;
    .iconfont {
      font-size: 30px;
      color: #999;
    }
    .van-search__content {
      background: #f5f5f5;
      border-radius: 4px;
      .van-cell {
        padding: 7px 2px 7px 0;
        .van-icon {
          color: #bfbfbf;
        }
        .van-field__body {
          padding-right: 10px;
        }
      }
    }
    .van-search__action {
      color: #999999;
      font-size: 14px;
      padding-left: 12px;
      .right-action {
        display: flex;
        align-items: center;
      }
      &:active {
        background: none;
      }
    }

    /deep/ .van-field__clear {
      width: 30px;
      text-align: center;
      z-index: 999;
    }
  }

  .popup-tags {
    /deep/ .van-sticky--fixed {
      top: 69px !important;
    }
    width: 100%;
    height: 260px;
    overflow: scroll;
    .tags-box {
      padding: 0 16px 14px;
      box-sizing: border-box;
      .title {
        font-size: 14px;
        color: #333333;
        padding: 10px 0 4px 0;
        font-weight: 500;
      }
      .first-tags-box {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        .tag-title {
          margin-top: 12px;
          margin-right: 8px;
          font-size: 12px;
          color: #b1b1b1;
          padding: 3px 16px;
          background: #f5f5f5;
          border: 1px solid #f5f5f5;
          border-radius: 19px;
        }
        .tag-active {
          color: #287de1 !important;
          background: rgba(40, 125, 225, 0.2) !important;
          border: 1px solid #287de1 !important;
        }
      }
    }
  }
}
</style>
